import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { ServicesModule } from "./services.module";
import { BehaviorSubject, Observable } from "rxjs";
import { SessionUser } from "src/app/shared/models/session-user.model";
import { User } from "../../shared/models/user.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserInactiveService } from "./user-inactive.service";
import { RolesEnum } from "src/app/shared/enums/roles.enum";
import { UserWorker } from "../../shared/models/user-worker.model";
import { GPDetails, ResourceFilter } from "../../shared/models";
import { LoginPortalType } from 'src/app/shared/enums/login-portal-type.enum';
import { HttpUserService } from "./http/http-user.service";
import { ActivityMethodEnum } from "src/app/shared/enums/activity-method.enum";
import { HttpCoreService } from "./http/httpcore.service";
import { ResourceService } from "./resource.service";
import { UserProfileModel } from "src/app/features/screener/user-profile/user-profile.model";
import { userAccessRightsModel } from "src/app/shared/models/user-access-right.model";
import { AdminUserModel } from "src/app/features/admin/admin-user.model";
import { userModuleAccess } from "src/app/shared/models/user-module-access.model";
import { userAssignedRight } from "src/app/shared/models/userAssignedRight.model";
import { StorageService } from "./storage.service";
import { LoginUser } from "src/app/shared/models/login-user.model";

@Injectable({
  providedIn: ServicesModule,
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<SessionUser>;
  public currentUser: Observable<SessionUser>;

  private readonly portal_angular: string = 'new_angular';

  constructor(
    private http: HttpClient,
    private router: Router,
    private _snackBar: MatSnackBar,
    private userInactiveService: UserInactiveService,
    private storageService: StorageService,
    private httpService: HttpCoreService,
    private resourceService: ResourceService
  ) {
    this.currentUserSubject = new BehaviorSubject<SessionUser>(
      JSON.parse(sessionStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();

    this.manageAutoLogout();
  }

  public get currentUserValue(): SessionUser {
    return this.currentUserSubject.value;
  }

  login(
    user : LoginUser
  ): void {
    var body = {
      username: user.email,
      password: user.password,
      grant_type: "password",
      campaignCode: user.campaignCode,
      schoolCode: user.schoolCode,
      tenantCode: user.tenantCode,
      application: this.portal_angular,
      loginMethod: user.loginMethod
    };
    var header = {
      "Content-Type": "application/x-www-form-urlencoded"
    };

    if(user.token) {
      body['token'] = user.token;
    }

    switch (user.portalType) {
      case LoginPortalType.User:
        header["X-Client"] = "UserPortal";
        body.tenantCode = "";
        break;
      case LoginPortalType.Clinician:
        header["X-Client"] = "ClinicianPortal";
        break;
      default:
        header["X-Client"] = "ScreenerPortal";
        break;
    }

    const options = {
      headers: new HttpHeaders(header),
    };

    let currentScope = this;
    this.http.post("token", this.parseBodyParams(body), options).subscribe(
      (data: any) => {
        if (data) {
          data.isNurse = data.isNurse == "True";
          data.tenantIdEntryEnabled = data.tenantIdEntryEnabled == "True";

          let userData = data as SessionUser;
          if (data.permissions) {
            userData.permissions = JSON.parse(data.permissions);
          }
          userData.visionTestManualMethodEnabled = data.visionTestManualMethodEnabled == "True";
          userData.visionTestInteractiveMethodEnabled = data.visionTestInteractiveMethodEnabled == "True";
          userData.visionTestKeelerMethodEnabled = data.visionTestKeelerMethodEnabled == "True";
          userData.tenantUnitOfMeasure = data.tenantUnitOfMeasure;
          userData.tenantTermStartingMonth = data.tenantTermStartingMonth;
          userData.tenantHealthIdVerificationEnabled = data.tenantHealthIdVerificationEnabled  == "True";
          userData.tenantChildMergeEnabled = data.tenantChildMergeEnabled  == "True";

          sessionStorage.setItem("currentUser", JSON.stringify(data));
          this.currentUserSubject.next(userData);
          this.userInactiveService.startWatching();
          let locale = JSON.parse(sessionStorage.getItem("locale"));
          let brand = JSON.parse(sessionStorage.getItem("brand"));
          let filter: ResourceFilter = { culture: locale != '' && locale != null ? locale : '', domainName: '', tenantCode: this.currentUserValue.tenantCode, branding: brand };
          this.httpService.services.resources.getTextResources(filter).subscribe((data: any) => {
            sessionStorage.setItem("resources", JSON.stringify(data));
            this.resourceService.cleanResourceCache();
            switch (userData.userRole) {
              case RolesEnum.User:               
                  this.router.navigate([`/forms`]);
                break;
              case RolesEnum.Nurse:
              case RolesEnum.Screener:
              case RolesEnum.Administrator:
              case RolesEnum.TenantAdministrator:
                this.router.navigate([`/children`]);
                break;
              case RolesEnum.ReadonlyReporting:
              case RolesEnum.ReadonlyKPIUser:
                this.router.navigate(['/school-folder']);
                break;
              case RolesEnum.Clinician:
                this.router.navigate(['/clinician']);
                break;
              default:
                this.router.navigate(["/error"]);
                break;
            }
          });
          this._snackBar.dismiss();
        } else {
          this.router.navigate(["/"]);
        }
      },
      function (error) {
        if(error && error.error && error.error && error.error.error == "is2FAToken")
        {
          if(user?.resendToken) {
            currentScope.openSnackBar(currentScope.resourceService.getResourceByKey('TokenResentCheckEmail'));
          } else {
          currentScope.storageService.set('mfaValues', user);
          currentScope.router.navigate(["/mfa"]);
          }
        }
        else {
          currentScope.openSnackBar(error.error.error_description);
        }
      }
    );
  }

  logout() {
    // remove user from local storage to log user out
    let userRole = this.currentUserValue.userRole;
    let tenantCode = this.currentUserValue.tenantCode;
    sessionStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);

    let localeSession = sessionStorage.getItem("locale");
    let brandSession = sessionStorage.getItem("brand");

    localStorage.clear();
    sessionStorage.clear()

    sessionStorage.setItem("locale", JSON.stringify(localeSession).replace(/\\"/g, ''));
    sessionStorage.setItem("brand", JSON.stringify(brandSession).replace(/\\"/g, ''));

    this.userInactiveService.stopWatching();
    switch (userRole) {
      case RolesEnum.User:
        this.router.navigate(["/login"]); // maybe a param should be added userRole
        break;
      case RolesEnum.Administrator:
      case RolesEnum.Clinician:
      case RolesEnum.Screener:
        this.router.navigate([`${tenantCode}/login`]);
        break;
      default:
        this.router.navigate(["/login"]);
        break;
    }
  }

  private manageAutoLogout() {
    if (sessionStorage.getItem("currentUser")) {
      this.userInactiveService.startWatching();
    }

    this.userInactiveService.timeout.subscribe(() => {
      this.logout();
    });
  }

  parseBodyParams(obj): string {
    let str: string[] = [];
    for (var p in obj)
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    return str.join("&");
  }

  register(user: User, campaignCode: string, schoolCode: string, registerMethod: ActivityMethodEnum, brand: string = "") {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      "api/account/register",
      { user, campaignCode, schoolCode, registerMethod, brand },
      options
    );
  }

  parentRegister(user: User, campaignCode: string, schoolCode: string, registerMethod: ActivityMethodEnum, brand: string = "", password: string = "") {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      "api/account/parent-register",
      { user, campaignCode, schoolCode, registerMethod, brand, password },
      options
    );
  }

  registerUserWorker(
    user: UserWorker,
    campaignCode: string,
    schoolCode: string,
    gpDetails: GPDetails,
    registerMethod: ActivityMethodEnum
  ) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      "api/account/register/worker",
      { user, campaignCode, schoolCode, gpDetails, registerMethod },
      options
    );
  }

  registerUserSelfCompletion(
    user: UserWorker,
    campaignCode: string,
    schoolCode: string,
    gpDetails: GPDetails,
    registerMethod: ActivityMethodEnum,
    password: string = ""
  ) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      "api/account/register/self-completion",
      { user, campaignCode, schoolCode, gpDetails, registerMethod, password },
      options
    );
  }
  
  registerClinician(
    user: UserProfileModel
  ) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      "api/account/register/clinician",
       user ,
      options
    );
  }

  createUserFromAdmin(adminUserModel:AdminUserModel, userAssignedRights: userAssignedRight[], userModuleAccesses: userModuleAccess[]) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      "api/account/createUserFromAdmin",
      { adminUserModel, userAssignedRights, userModuleAccesses },
      options
    );
  }

  parentRegisterConfirmation(userEmail: string, code: string) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      "api/account/parentConfirmation",
      { userEmail, code },
      options
    );
  }

  forgotPassword(email: string) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      "api/parent/forgotPassword",
      { email },
      options
    );
  }

  validateResetPassword(userEmail: string, code: string) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      "api/parent/validateResetPassword",
      { userEmail, code },
      options
    );
  }

  resetPassword(email: string, code: string, password: string) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      "api/parent/resetPassword",
      { email, code, password },
      options
    );
  }  

  openSnackBar(message) {
    this._snackBar.open(message, "Close", {
      panelClass: ["snackbar-alert"],
      duration: 30000,
    });
  }

  resetCurrentUser(firstName: string, lastName: string) {
    this.currentUserSubject.value.firstName = firstName;
    this.currentUserSubject.value.lastName = lastName;
    this.currentUserSubject.value.userName = firstName + ' ' + lastName;
    sessionStorage.setItem("currentUser", JSON.stringify(this.currentUserSubject.value));
    this.currentUserSubject.next(this.currentUserSubject.value);
  }

  goToChildrenPage() {
    this.router.navigate([`/children`]);
  }

  goToTenantChildrenPage(tenantId: string) {
    this.router.navigate(['/clinician-children/' + tenantId + '/']);
  }
}
