import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GdprComponent } from './gdpr/gdpr.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CookieService } from 'ngx-cookie-service';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { PdfPreviewComponent } from './pdf-preview/pdf-preview.component';
import { LetterPreviewComponent } from './letter-preview/letter-preview.component';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SocialLoginComponent } from './social-accounts/social-login/social-login.component';
import { MessagePreviewComponent } from './message-preview/message-preview.component';
import { SocialLogin1Component } from './social-accounts/social-login1/social-login1.component';
import { MatDividerModule } from '@angular/material/divider';
import { TermsCheckboxComponent } from './terms-checkbox/terms-checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormlyModule } from '@ngx-formly/core';
import { CheckWhitespace, CheckWhitespaceValidatorMessage, EmailInvalidValidatorMessage, RequiredValidatorMessage, ValidateEmail } from '../validators';
import { FormlyUkPostcodeLookupComponent } from './formly-uk-postcode-lookup/formly-uk-postcode-lookup.component';
import { FormlyInputDatepickerComponent } from './formly-input-datepicker/formly-input-datepicker.component';
import { RegisterErrorComponent } from './register-error/register-error.component';

@NgModule({
  declarations: [
    GdprComponent,
    LoginComponent,
    ImagePreviewComponent,
    FilePreviewComponent,
    PdfPreviewComponent,
    LetterPreviewComponent,
    MessagePreviewComponent,
    SocialLoginComponent,
    SocialLogin1Component,
    TermsCheckboxComponent,
    RegisterErrorComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    RouterModule,
    SharedPipesModule,
    PdfViewerModule,
    MatDividerModule,
    MatCheckboxModule,
    FormlyModule.forRoot({
      validators: [
          { name: 'whitespace', validation: CheckWhitespace },
          { name: 'emailInvalid', validation: ValidateEmail }
      ],
      validationMessages: [
          { name: 'whitespace', message: CheckWhitespaceValidatorMessage },
          { name: 'required', message: RequiredValidatorMessage },
          { name: 'emailInvalid', message: EmailInvalidValidatorMessage }
      ],
      types: [
          { name: 'termsCheckbox', component: TermsCheckboxComponent },
          { name: 'inputDatepicker', component: FormlyInputDatepickerComponent },
          { name: 'ukPostcodeLookup', component: FormlyUkPostcodeLookupComponent }
      ],
  }),
  ],
  exports: [
    GdprComponent,
    LoginComponent,
    SocialLoginComponent,
    ImagePreviewComponent,
    FilePreviewComponent,
    PdfPreviewComponent,
    LetterPreviewComponent,
    MessagePreviewComponent,
    SocialLogin1Component
  ],
  providers: [CookieService],
})
export class SharedComponentsModule { }
