<div #loginContainer>
  <div class="landing-inactive-page" *ngIf="isFromLandingPage">
    <div>
    <p class="title">Sorry, this page isn't available </p>
    </div>
    <div>
      <p class="text">{{landingPageText1}} </p>
    </div>
    <div>
      <p class="text bottom">{{landingPageText2}} </p>
    </div>
  </div>
  <form [formGroup]="loginForm" (ngSubmit)="login()">
    <br *ngIf="isCampaignLandingPage">
    <div class="login-now field-width" *ngIf="isCampaignLandingPage">
      <span>{{ 'IfYouHaveExistingAccount' | resourcekey }}<b> {{ 'LoginNow' | resourcekey }}</b></span>
    </div>
    <br *ngIf="isCampaignLandingPage">
    <mat-form-field class="field-width">
      <input matInput type="email" placeholder="{{ 'Common_EmailAddress' | resourcekey }}" formControlName="email" #email>
      <mat-error *ngIf="formControls.email.errors?.email && !formControls.email.errors?.required">
        {{ 'EnterAValidEmailAddress' | resourcekey }}
      </mat-error>
      <mat-error *ngIf="formControls.email.errors?.required">
        {{ 'Common_Email' | resourcekey }} {{ 'Common_Is' | resourcekey }} <strong>{{ 'Common_Required_Lower' | resourcekey }}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="field-width">
      <input matInput type="password" placeholder="{{ 'Login_Password' | resourcekey }}" formControlName="password">
      <mat-error *ngIf="formControls.password.hasError('required')">
        {{ 'Login_Password' | resourcekey }} {{ 'Common_Is' | resourcekey }} <strong>{{ 'Common_Required_Lower' | resourcekey }}</strong>
      </mat-error>
      <mat-error *ngIf="formControls.password.errors?.minlength">
        {{ 'Login_Password_Error' | resourcekey }}
      </mat-error>
    </mat-form-field>
    <div class="forgot-password field-width">
      <span (click)="forgotPassword()">{{ 'ForgotPassword_Title' | resourcekey }}</span>
    </div>
    <div>
      <button type="submit" class="outline btn-rounded-login field-width">{{ 'Login_Login' | resourcekey }}</button>
    </div>

  <app-social-login *ngIf="!tenantCode" (loggedUser)="loginWithSocialAccount($event)" [showRegister]="showRegister" [isRegister] = "false" ></app-social-login>
    <div class="register field-width empty-line" *ngIf="showRegister">
      {{ 'DontHaveAnAccount' | resourcekey }} <a class="register-button" color="primary" routerLink="register">{{ 'RegisterNow' | resourcekey }}</a>
    </div>
  </form>
  <div style="text-align: center;" *ngIf="tenantCode">
    <button (click)="registerClinician()" class="outline btn-rounded-clinician field-width">{{ 'RegisterAsClinician' | resourcekey }}</button>
  </div>
</div>

