import { TestTypes } from 'src/app/features/screener/shared/enums';
import { Vaccine } from '.';
import { CampaignTypeEnum } from '../enums/campaign-type.enum';
import { CampaignPortalTypeEnum } from '../enums/campaing-portal-type.enum';

export interface Campaign {
  id: string;
  name: string;
  ageGiven: string;
  code: string;
  youngConsent: boolean;
  shortDescription: string;
  color: string;
  image: string;
  description: string;
  urlDetails?: any;
  campaignSchools: string;
  vaccines: Vaccine[];
  campaignType: CampaignTypeEnum;
  relatedModule?: TestTypes;
  isAddressHidden: boolean;
  isGPDetailHidden: boolean;
  isYearGroupVisible: boolean;
  isYearGroupMandatory: boolean;
  isClassVisible: boolean;
  isClassMandatory: boolean;
  isEthnicityVisible: boolean;
  isNhsNumberVisible: boolean;
  dobRangeStartDate?: Date;
  dobRangeEndDate?: Date;
  portalType: CampaignPortalTypeEnum;
  tenantId: string;
}
